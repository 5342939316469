require('./style.less');

document.querySelectorAll('.collapsable').forEach(function (element) {
    element.querySelector('.toggle').addEventListener('click', function (e) {
        element.classList.toggle('collapsed');

        e.preventDefault();
    });
});
document.querySelectorAll('.menu-toggle').forEach(function (toggle) {
    toggle.addEventListener('click', function (e) {
        document.querySelectorAll('.menu-container').forEach(function (element) {
            element.classList.toggle('open');
        });
        document.body.classList.toggle('noscroll');

        e.preventDefault();
    });
});
document.querySelectorAll('.glossary-pagination select').forEach(function (toggle) {
    toggle.addEventListener('change', function (e) {
        window.location = e.target.value;
    });
});

function filterModels(container, filters) {
    let anyVisible = false;
    const filterableOptions = {};

    container.querySelectorAll('.card').forEach(function (card) {
        let visible = true;

        for (let key of Object.keys(filters)) {
            if (filters[key] === '') {
                continue;
            }
            if (card.dataset[key] !== filters[key]) {
                visible = false;
                break;
            }
        }

        if (visible) {
            card.style.display = 'block';
            anyVisible = true;

            for (let key of Object.keys(filters)) {
                if (!filterableOptions.hasOwnProperty(key)) {
                    filterableOptions[key] = new Set();
                }

                filterableOptions[key].add(card.dataset[key])
            }
        } else {
            card.style.display = 'none';
        }
    });

    let placeholder = container.querySelector('.empty-filter');

    if (!anyVisible) {
        placeholder.style.display = 'block';
    } else {
        placeholder.style.display = 'none';
    }

    document.querySelectorAll('.model-overview .filters select').forEach(function (toggle) {
        const target = toggle.dataset.target;

        toggle.querySelectorAll('option').forEach((o) => {
            if (o.value === "") {
                return; // Always show the reset option.
            }

            if (filterableOptions[target].has(o.value)) {
                o.style.display = 'unset';
            } else {
                o.style.display = 'none';
            }
        });
    });
}

function toggleChip(filter, value, label) {
    const chip = filter.querySelector('.chip');
    const select = filter.querySelector('select');

    if (value === "") {
        chip.style.display = 'none';
        select.style.display = 'unset';

        return;
    }

    chip.querySelector('.label').innerHTML = label;
    chip.style.display = 'flex';
    select.style.display = 'none';
}

document.querySelectorAll('.model-overview').forEach(function (overview) {
    let filters = {};

    overview.querySelectorAll('.filters .filter').forEach(function (filter) {
        const toggle = filter.querySelector('select');

        filters[toggle.dataset.target] = toggle.value;

        toggle.addEventListener('change', function (e) {
            filters[toggle.dataset.target] = e.target.value;
            filterModels(overview, filters);
            toggleChip(filter, e.target.value, toggle.options[toggle.selectedIndex].text);
        });
        filter.querySelector('.chip .clear-filter').addEventListener('click', function () {
            toggle.value = "";
            filters[toggle.dataset.target] = "";
            filterModels(overview, filters);
            toggleChip(filter, "", "");
        });
    });

    filterModels(overview, filters);
});

document.querySelector('.menu-search-container .trigger').addEventListener('click', function (e) {
    let container = document.querySelector('.header-container');

    if (container.classList.contains('search-open')) {
        return;
    }
    container.classList.add('search-open');

    e.preventDefault();
    return false;
});

document.body.addEventListener('click', function (e) {
    let searchContainer = document.querySelector('.menu-search-container');

    if (e.target !== searchContainer && !searchContainer.contains(e.target)) {
        document.querySelector('.header-container').classList.remove('search-open');
    }
});
document.querySelector('.breadcrumb .back-arrow').addEventListener('click', function (e) {
    document.querySelector('.breadcrumb a:last-of-type').dispatchEvent(new MouseEvent('click', {bubbles: true}));
});

function updateSplitViewHeights() {
    document.querySelectorAll(".split-view").forEach(function(splitView) {
        let height = splitView.querySelector(".column:not(.image-content) > div").clientHeight;

        splitView.querySelector(".column.image-content img").style.height = height + "px";
    });
}

window.addEventListener('resize', function (e) {
    updateSplitViewHeights();
});

updateSplitViewHeights();
